import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./icon"

const Header = ({ siteTitle, beta }) => (
  <header
    style={{
      margin: `0 auto`,
      padding: `var(--space-4) 32px`,
    }}
    className="sm:flex sm:align-center sm:justify-between"
  >
    <div className="flex flex-row justify-center align-center">
      <Logo />
      <Link
        to="/"
        style={{
          color: "black",
          marginLeft: "10px",
          fontSize: `var(--font-xl)`,
          textDecoration: `none`,
        }}
      >
        {siteTitle}
        {beta && <sup className="font-light">beta</sup>}
      </Link>
    </div>
    <div className="flex justify-around px-10 sm:px-0 items-center gap-5 mt-5 sm:mt-0 sm:block">
      {/*
      <a
        className="text-black sm:mr-5 no-underline text-sm flex-shrink-0 border-4 py-1 px-2 rounded hover:bg-yellow-400 bg-yellow-200 border-yellow-200 hover:border-yellow-400"
        href="https://the.datachimp.app/login"
      >
        Login
      </a>
      <Link
        className="sm:mr-5"
        style={{
          color: "black",
          fontSize: "var(--font-lg)",
        }}
        to="/docs"
      >
        Docs
      </Link>
     */}
      <Link style={{ color: "black", fontSize: "var(--font-lg)" }} to="/blog">
        Blog
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
