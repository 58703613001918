exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-functions-arent-enough-mdx": () => import("./../../../src/pages/blog/functions-arent-enough.mdx" /* webpackChunkName: "component---src-pages-blog-functions-arent-enough-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-intro-to-metaprogramming-for-r-users-mdx": () => import("./../../../src/pages/blog/intro-to-metaprogramming-for-r-users.mdx" /* webpackChunkName: "component---src-pages-blog-intro-to-metaprogramming-for-r-users-mdx" */),
  "component---src-pages-blog-the-code-chat-gpt-cant-write-mdx": () => import("./../../../src/pages/blog/the-code-chat-gpt-cant-write.mdx" /* webpackChunkName: "component---src-pages-blog-the-code-chat-gpt-cant-write-mdx" */),
  "component---src-pages-blog-tidy-tuesday-july-12-mdx": () => import("./../../../src/pages/blog/tidy-tuesday-july-12.mdx" /* webpackChunkName: "component---src-pages-blog-tidy-tuesday-july-12-mdx" */),
  "component---src-pages-blog-vscode-for-data-science-mdx": () => import("./../../../src/pages/blog/vscode-for-data-science.mdx" /* webpackChunkName: "component---src-pages-blog-vscode-for-data-science-mdx" */),
  "component---src-pages-blog-window-managment-for-r-studio-mdx": () => import("./../../../src/pages/blog/window-managment-for-RStudio.mdx" /* webpackChunkName: "component---src-pages-blog-window-managment-for-r-studio-mdx" */),
  "component---src-pages-docs-index-mdx": () => import("./../../../src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-successful-signup-js": () => import("./../../../src/pages/successful-signup.js" /* webpackChunkName: "component---src-pages-successful-signup-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

