import * as React from "react"

const SvgComponent = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={48.127}
    height={39.458}
    {...props}
  >
    <path
      d="M14420 13085.2s117 685.3 853.6 686c707.6.7 1026.4-686 945.9-1202.9-84.7-543-369-832.2-793.7-965.4-443.6-139.2-906-34.1-906-34.1l-99.8 1516.4"
      style={{
        fill: "#ffca9f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00436 0 0 -.00436 -23.002 79.602)"
    />
    <path
      d="M16300 12955c-102 397.9-412.9 760.3-929.2 760.3h-1.1c-84.9-.1-161.7-9.2-231-25.2.9 28.9 1.5 57.8 1.8 86.6 81 27.1 175.1 43.3 284.2 43.4h1c256.2 0 476.4-98.9 636.7-285.9 132.9-155 217.1-366.2 237.6-579.2"
      style={{
        fill: "#ffdfc5",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00432 0 0 -.00432 -23.002 79.602)"
    />
    <path
      d="M15160.5 13438.5s70.9 391 517.4 391.5c428.9.4 622.1-391.5 573.3-686.5-51.2-309.8-223.6-474.9-481-550.9-268.9-79.4-549.2-19.5-549.2-19.5l-60.5 865.4"
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00415 0 0 -.00415 -23.002 79.602)"
    />
    <path
      d="M15392.3 12404.8c-144.9 0-251 22.1-258.3 23.7-48.8 10.4-79.9 58.5-69.5 107.3 10.4 48.8 58.8 80 107.3 69.5 2.4-.5 260.9-53.6 502.2 17.6 241.4 71.3 373.3 222.7 415.3 476.6 24 144.4-20.9 304.4-117 417.6-62.1 73.2-175.8 160.6-363.8 160.6h-.6c-354.8-.3-420.1-283.7-426.4-316.1-9.5-48.7-56.8-81-105.5-71.8-48.8 9.1-81.1 55.8-72.3 104.6 29.1 160.3 192.8 463.7 604 464h.8c257.1 0 414.7-122 501.7-224.4 129.5-152.5 189.8-368.7 157.5-564-53.1-321.1-235.7-529.8-542.5-620.4-116.3-34.4-233.7-44.8-332.9-44.8"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00416 0 0 -.00416 -23.002 79.602)"
    />
    <path
      d="M14864.2 11404c-232.5 0-402.6 37.7-414.3 40.3-46.3 10.6-75.4 56.7-64.9 103 10.6 46.4 56.7 75.5 103 64.9 4.4-1 444.5-97.6 858 32.1 425 133.3 657.4 417.1 731.6 893.2 44.2 283.3-45.4 604.2-228.1 817.4-158.2 184.4-375.4 282-628.3 282h-1c-654-.6-761.7-587.1-765.9-612.1-8-46.8-52.3-78.3-99.3-70.3-46.9 8-78.4 52.5-70.4 99.3 1.3 7.6 138 754.4 935.5 755.2h1.1c304.7 0 567-118.3 758.9-342.2 217.4-253.5 319.9-619.8 267.6-955.7-84.3-540.8-362.4-878-850.2-1031-186.4-58.4-374.4-76.1-533.3-76.1"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00437 0 0 -.00437 -23.002 79.602)"
    />
    <path
      d="M8492.62 15487.2s-138.4 811.1-1010.37 812c-837.52.8-1214.75-812-1119.45-1423.7 100.14-642.7 436.65-985 939.33-1142.7 525.12-164.7 1072.27-40.3 1072.27-40.3l118.22 1794.7"
      style={{
        fill: "#ffca9f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00368 0 0 -.00368 -23.002 79.602)"
    />
    <path
      d="M6550.55 14921.4c-18.5 35.8-35.84 73-51.95 111.6-36.39 326.6 70.64 686.7 279.01 929.8 189.02 220.6 448.68 337.2 750.92 337.2h1.23c147.23-.2 271.43-25.5 375.97-66.6.26-25.1.84-50.1 1.62-75.2-66.44 11.6-138.24 18.1-215.8 18.2h-1.3c-762.88 0-1145.85-671.2-1139.7-1255"
      style={{
        fill: "#ffdfc5",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00366 0 0 -.00366 -23.002 79.602)"
    />
    <path
      d="M8688.87 15115.6c-188.54 0-410.65 20.5-627.73 88.6-453.12 142.1-738.61 415.3-880.86 849.2-3.04 17.3-6.01 34.7-8.76 52.5-9.83 63-15.05 128.1-15.76 194.1 199.14-385.5 520.32-620.7 935.98-751.2 251.22-78.9 506.6-102.6 723.05-102.6 162.32 0 302.88 13.4 403.16 26.8 1.83-2.9 3.6-5.9 5.44-8.8-136.74-59.4-253.2-161.7-342.1-301.8-8.69-13.6-16.96-27.5-25.01-41.5-51.66-3.3-107.84-5.3-167.41-5.3"
      style={{
        fill: "#d08d6f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00335 0 0 -.00335 -23.002 79.602)"
    />
    <path
      d="M7126.98 15934.3c-31.7 96.7-56.25 201.5-73.86 314.6-2.67 16.9-4.91 34-6.8 51.1 17.46-41.9 36.26-82.2 56.32-121 .7-65.5 5.89-130.1 15.64-192.7 2.74-17.6 5.68-34.9 8.7-52"
      style={{
        fill: "#d09c8a",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00338 0 0 -.00338 -23.002 79.602)"
    />
    <path
      d="M15066.4 11733c-33.1 0-65 .8-95.1 2.1-6.5 11.6-13.4 23-20.4 34.2-56.1 88.4-126.4 157.3-207.7 204.8 32.5-2.5 69.4-4.2 109.4-4.2 72.5 0 155.2 5.7 239.9 22.9 137.6 7.8 288.2 29.6 436.9 76.2 355.4 111.5 621.8 321.7 770.6 677.7-1.5-13.4-3.2-26.6-5.3-39.9-75.2-482.5-310.8-770.2-741.5-905.3-168-52.7-340.4-68.5-486.8-68.5"
      style={{
        fill: "#d08d6f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00432 0 0 -.00432 -23.002 79.602)"
    />
    <path
      d="M16040.9 12927.5c-43.2 0-83 1.9-118.1 4.6-20.2 11.8-40.9 22.3-62.3 31.6.6 1 1.2 2 1.9 3 80.5-9.6 185-18.1 303.4-18.1 43 0 87.9 1.1 134.2 3.8-91.5-18.7-180.7-24.9-259.1-24.9"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.004 0 0 -.004 -23.002 79.602)"
    />
    <path
      d="M14976.7 11576.5c-17.2 0-34.1.2-50.5.6-13 30.4-27.4 60-43.6 88.5 29.9-1.4 61.5-2.2 94.5-2.2 145.6 0 316.9 15.8 483.9 68.2 428.1 134.3 662.3 420.2 737.1 899.9 2.1 13.2 3.8 26.4 5.3 39.7 33.4 79.9 60.9 167.3 82 262.6 14.6-107.3 14.2-214.8-1.6-315.6-85-545.3-370.4-835.7-796.8-969.4-177.3-55.6-357.5-72.3-510.3-72.3"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00434 0 0 -.00434 -23.002 79.602)"
    />
    <path
      d="M8891 15838.1s-83.51 460.9-609.84 461.4c-505.46.5-733.12-461.4-675.65-809 60.5-365.2 263.59-559.7 566.97-649.3 316.9-93.6 647.19-22.9 647.19-22.9l71.33 1019.8"
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00352 0 0 -.00352 -23.002 79.602)"
    />
    <path
      d="M7605.6 15490.6c60.44-365.2 263.47-559.7 566.84-649.3 34.73-10.1 69.52-18.4 104.04-25.1 281.37-53.8 543.29 2.1 543.29 2.1l71.4 1020s-21.94 121.2-114.47 239.4c-87.75 112.3-238.9 221.8-495.44 222-505.53.3-733.19-461.4-675.66-809.1"
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00352 0 0 -.00352 -23.002 79.602)"
    />
    <path
      d="M9560.07 16286.4c-27.34 0-55.87.5-85.13 1.6 56.3 2.4 105.43 7.2 144.03 12 2.44-4.2 4.82-8.4 7.26-12.7-21.11-.6-43.27-.9-66.16-.9"
      style={{
        fill: "#d08d6f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0032 0 0 -.0032 -23.002 79.602)"
    />
    <path
      d="M9474.89 16287.9h-.96c56.75 2.5 106.17 7.2 144.92 12.1l.08-.1c-38.61-4.8-87.73-9.5-144.04-12"
      style={{
        fill: "#c75c52",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0032 0 0 -.0032 -23.002 79.602)"
    />
    <path
      d="M8981.27 15440.9c-60.27 2.3-124.39 7.5-189.44 16.8-114.41 84.7-192.31 200.5-226.37 355.6-35.82 162.3 34.06 356.4 207.97 486.7 88.22-315.2 204.75-602.5 345.22-847.6-36.73-4.7-83.58-9.2-137.38-11.5"
      style={{
        fill: "#c75c52",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00337 0 0 -.00337 -23.002 79.602)"
    />
    <path
      d="M15866.1 12972.4c-33.1 0-64.1 1.3-91.8 3.2 102.3 196 187.5 420.3 252.6 663.6 192.8-105.2 273.1-293.9 239.1-448-13.8-63-36.3-118.1-66.7-166.3-14.1-5-28.7-9.8-43.7-14.2-99.7-29.4-202.2-38.3-289.5-38.3"
      style={{
        fill: "#c75c52",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00404 0 0 -.00404 -23.002 79.602)"
    />
    <path
      d="M15949.6 12959.2c-47.9 0-91.4 1.9-128.1 4.4 17.4 30.1 34.3 61 50.8 92.6 27.9-1.9 59.1-3.2 92.4-3.2 87.8 0 190.9 8.9 291.3 38.5 15.1 4.5 29.7 9.3 44 14.4-32.3-51.3-73.6-94.7-123-131.3-80-11.5-158.3-15.4-227.4-15.4"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00402 0 0 -.00402 -23.002 79.602)"
    />
    <path
      d="M8480.89 14590.3c-116.6 0-254.67 12.2-391.53 52.6-360.91 106.6-575.6 352.1-638.04 729.8-38.05 229.8 32.96 484 185.2 663.3 102.29 120.5 287.7 264 590.1 264h.94c483.73-.4 676.22-357.2 710.38-545.7 10.43-57.8-27.88-113.1-85.64-123.6-57.24-10.4-112.73 27.6-123.49 85.1-7.49 38.6-84.58 371.3-501.45 371.7h-.67c-221.24 0-354.89-102.8-428.04-189-112.99-133.1-165.67-321.3-137.6-491.1 49.42-298.6 204.66-476.8 488.48-560.6 283.96-83.8 587.83-21.3 590.84-20.7 57.37 12.1 113.8-24.5 125.97-81.9 12.23-57.4-24.27-113.8-81.64-126.1-8.62-1.8-133.32-27.8-303.81-27.8"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00354 0 0 -.00354 -23.002 79.602)"
    />
    <path
      d="M7856.23 13461.2c-187.63 0-409.62 20.8-629.57 89.8-575.85 180.6-904.1 578.7-1003.54 1216.9-61.8 396.6 59.21 828.9 315.76 1128.2 226.47 264.2 536.24 403.9 895.85 403.9h1.34c941.32-.9 1102.7-882.5 1104.23-891.4 9.39-55.3-27.73-107.8-83.05-117.3-55.13-9.3-107.58 27.7-117.16 82.8-5.24 29.5-136.46 722-904.15 722.8h-1.21c-298.47 0-554.9-115.2-741.57-332.9-215.73-251.7-321.43-630.4-269.33-964.8 87.58-562 361.97-897 863.58-1054.4 489.92-153.6 1007.57-39.1 1012.75-37.9 54.68 12.5 109.11-21.9 121.57-76.5 12.46-54.7-21.85-109.2-76.53-121.6-13.86-3.1-214.65-47.6-488.97-47.6"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0037 0 0 -.0037 -23.002 79.602)"
    />
    <path
      d="M6029.62 12092c28.62-534.6 174.3-1102.4 444.57-1484.4-247.59-45.5-368.93-284.6-404.12-533-20.88-146.5-12.02-296.22 18.54-411.38 107.96-408.35 443.45-653.71 918.78-824.5 30.57-10.85 61.52-21.51 93.26-31.93-82.99 37.53-161.54 87.22-233.53 145.09-253.43 203.36-426.95 507.46-442.62 745.61-.39 8.91-.78 17.81-.97 26.52-.2 10.03-.2 20.11.38 29.79 0 8.13.39 16.26.98 23.95 11.78 195.61 104.45 333.55 209.29 428.15 150.15 135.4 325.42 182.4 325.42 182.4s-708.67 1333.4 4.09 2484.9c5.79 9.3 11.38 18.8 17.37 27.8 399.89 624.3 926.72 865.2 1409 872.6.98.2 2.15.2 3.12 0 623.92 8.3 1172.55-374.4 1273.74-823.4 11.05-48.8 16.84-98.1 16.84-148h16.07c0 498.7 574.37 960.9 1247.47 971.4 494.4 7.7 1042.5-228.3 1455.2-872.6 6-9 11.8-18.5 17.4-27.8 712.8-1151.5 4.3-2484.9 4.3-2484.9s.9-.2 2.7-.7c21-6 160.9-49.1 292.3-155.7 113.9-92.5 221.5-233.18 238.3-439.21 2.2-22.1 3-44.92 2.6-68.68-1.4-88.63-26.2-187.87-69.9-288.28v-.19c-64.6-148.4-170.8-299.68-304.7-423.89-89.8-83.57-192.1-154.77-302.6-204.86 524.7 171.96 897.3 421.75 1012.2 856.43 32 120.56 40.1 278.99 15.3 431.98-39.1 240.9-160.4 468.2-400.8 512.4 270.2 382 415.7 949.8 444.6 1484.4 89.9 1680.2-1246.7 3080.5-2932.2 3368.4l-.4.2c-150.9 47.7-394.6 127.5-720.02 240.8-387.14 134.7-652.6 421.4-763.81 560.3-30.57 38.3-89.75 35.2-116.28-6.2-64.05-99.4-165.97-348.2-226.76-545-13.53-43.7-64-63.6-103.87-41.2-72.91 41.4-184.95 180.9-290.76 262.5-36.55 28.3-90 14.9-108.93-27-56.51-125.4-148.79-390.5-136.38-765.8-1177.62-567.8-1996.37-1720.3-1925.17-3047"
      style={{
        fill: "#b74729",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00486 0 0 -.00486 -23.002 79.602)"
    />
    <path
      d="M7947.35 15207.3c-2.04 345.4 83.8 590.2 137.39 709.2 12.11 26.8 38.37 42 65.27 42 15.12 0 30.44-4.8 43.57-15 105.73-81.6 217.68-220.9 290.54-262.3 11.23-6.3 23.34-9.3 35.21-9.3 30.15 0 58.89 19.1 68.57 50.5 60.74 196.6 162.58 445.2 226.58 544.5 14.01 21.9 37.16 33.1 60.41 33.1 20.66 0 41.38-8.9 55.78-26.9 111.12-138.8 376.37-425.3 763.2-559.8 294.77-102.7 522.43-177.8 674.83-226.5-4.2-6.4-7.4-13.5-9.6-21.3-152.2 48.8-376.59 122.9-665.23 223.4-386.83 134.6-652.08 421.1-763.2 559.9-14.4 18-35.12 26.8-55.78 26.8-23.25 0-46.4-11.1-60.41-33-64-99.4-165.84-348-226.58-544.6-9.68-31.3-38.42-50.4-68.57-50.4-11.87 0-23.98 2.9-35.21 9.3-72.86 41.3-184.81 180.7-290.54 262.3-13.13 10.1-28.45 14.9-43.57 14.9-26.9 0-53.16-15.1-65.27-42-52.33-116.1-135.39-352.2-137.39-684.8"
      style={{
        fill: "#d7fcb0",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00487 0 0 -.00487 -23.002 79.602)"
    />
    <path
      d="M13226.2 12863.4c-379.7 1182.6-1482.2 2083.4-2796.9 2307.9l-.4.2c-151 47.8-394.9 127.6-720.53 241-387.41 134.8-653.05 421.7-764.34 560.7-14.42 18-35.17 26.9-55.87 26.9-23.28 0-46.46-11.2-60.49-33.1-64.1-99.5-166.09-348.5-226.92-545.4-9.69-31.4-38.48-50.5-68.68-50.5-11.88 0-24.01 3-35.26 9.3-72.96 41.4-185.08 181-290.97 262.7-13.2 10.2-23.67 15-32.53 15-15.64 0-26.11-15.1-37.41-42.3-34.78-83.6-46.42-290.3-34.05-665.9-945.77-433.2-1637.74-1190.8-1925.1-2070.2 207.54 923.4 867.26 1742.2 1817.22 2200.2 38.53 18.6 54.7 64.9 36.14 103.4-13.35 27.7-41.01 43.8-69.8 43.8h-1.02c-.05 1-.05 2-.05 2.9 2 333.1 85.19 569.6 137.6 685.9 12.12 26.9 38.43 42 65.36 42 15.15 0 30.49-4.7 43.64-14.9 105.89-81.7 218.01-221.3 290.97-262.7 11.25-6.4 23.38-9.3 35.26-9.3 30.2 0 58.99 19.1 68.68 50.5 60.83 196.9 162.82 445.9 226.92 545.4 14.03 21.9 37.21 33.1 60.49 33.1 20.7 0 41.45-8.9 55.87-26.9 111.29-139 376.93-425.9 764.34-560.7 289.07-100.7 513.83-174.9 666.23-223.7-.2-.8-.4-1.7-.6-2.5-10-41.4 15.2-83 56.4-93.4l7.2-1.5c54.4-9.7 104.1-19.6 152-30.5l9.2-1.9h.2c1360.4-302.8 2369.8-1296.3 2627.2-2495.5"
      style={{
        fill: "#d4917f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00486 0 0 -.00486 -23.002 79.602)"
    />
    <path
      d="M7600.84 9344.33c-8.54 0-17.24 1.36-25.79 4.17-657.35 215.44-1018.82 525.07-1137.61 974.3-61.32 231.7-52.94 598.4 110.5 856 65.54 103.3 151.41 178.8 252.22 222.6-241.75 390-396.64 942.7-428.53 1538-37.46 698.2 150.16 1384.8 542.47 1985.2 374.97 573.9 916.19 1042.6 1565.05 1355.5 41.21 19.9 90.71 2.6 110.61-38.6 19.85-41.2 2.56-90.7-38.66-110.6-1293.7-623.8-2084.28-1873.1-2013.99-3182.7 32.4-605.7 204.7-1183.5 460.77-1545.6l74.92-105.9-127.59-23.4c-105.97-19.4-192.56-81.1-257.38-183.2-129.31-203.9-142.96-525.6-90.24-724.9 104.52-395.24 421.86-660.23 1029.04-859.3 43.45-14.23 67.16-61.01 52.94-104.52-11.47-34.9-43.87-57.05-78.73-57.05"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00454 0 0 -.00454 -23.002 79.602)"
    />
    <path
      d="M12882.7 9155.67c-34.1 0-65.9 21.69-77.1 55.9-13.9 42.62 9.2 88.46 51.9 102.4 595 195.11 906 454.74 1008.4 841.93 51.6 195.4 38.1 510.8-88.6 710.4-63.5 100-148.3 160.4-252 179.4l-125 23 73.4 103.7c254.4 359.7 419 911.7 451.5 1514.5 85.5 1595.2-1098.5 3028.3-2816.5 3410.8h-.1l-9.7 1.9c-50.2 11.4-102.3 21.8-159.3 32l-7.5 1.6c-43.2 10.8-69.7 54.5-59.2 97.8 10.3 42.5 52.3 69 94.7 60.5l1.8-.3c56.1-10.1 107.8-20.4 157.8-31.5l7.9-1.6c846.3-186.5 1610.7-639.7 2152.3-1276.2 560.1-658.3 844.2-1476.4 799.9-2303.8-31.5-583.6-183.2-1125-419.9-1506.9 98.7-42.9 182.7-116.8 246.9-217.9 160.2-252.3 168.5-611.7 108.5-838.9-116.3-440.13-470.6-743.51-1114.8-954.65-8.4-2.75-16.9-4.08-25.3-4.08"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00464 0 0 -.00464 -23.002 79.602)"
    />
    <path
      d="M7586.73 11497.4c0-1.4 3.39-12 10.07-30.3 45.29-123.9 240.63-598.9 523.39-881.6 52.37-52.4 107.74-98.1 165.87-133.9 23.07-14.4 46.67-27 70.44-37.7 52.84-17.4 107.05-33.9 162.19-49.7 17.61-5.2 35.22-10 52.83-14.8 291.05-79.8 450.24-148.3 644.71-207.9 155.57-47.9 333.52-90.2 619.5-127.9 648.47-85.84 1217.47-163.13 1552.17-163.13 518.6 0 1398 96.52 2260.4 324.83 368.1 97.7 733 219.2 1065.6 367.4l.5.4c58.5 35.1 114.8 72.6 167.7 113.1 177 134.9 314 300.6 360.2 501.2v.2c22.7 97 23.8 202.5-1.8 316.4-1.6 8 68.4 67.7 66.4 75.3-1.4 5.7-2.8 11.4-4.4 16.9-66.3 260.5-163.3 426.9-262.4 532.8-136.8 146.4-277 178-345.7 184.1-27.2 2.6-43 .9-43 .9s818.8 1576.7-24 2938.3c-6.9 11.2-13.7 22.2-20.8 32.9-471.7 736.4-1092.6 1021.7-1661.7 1031.8-741 13-1393.9-440.9-1514-973.6-13-57.7-19.9-116-19.9-175.1 0 592.6-685.6 1141.4-1486.29 1148.7-1.15.2-2.53.2-3.68 0-580.83 4.3-1222.03-276.4-1705.88-1031.8-7.08-10.7-13.75-21.7-20.6-32.9-842.75-1361.6-4.84-2938.3-4.84-2938.3s-171.79-2.9-344.97-215.7c-87.6-107.5-175.88-268.6-242.24-510.1-2.08-8-4.32-16.2-6.39-24.5-3.23-11.9-6.39-24-9.38-36.3"
      style={{
        fill: "#ffca9f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00411 0 0 -.00411 -23.002 79.602)"
    />
    <path
      d="M15136.2 13971.1c0-178.6 260.5-323.4 581.9-323.4s581.9 144.8 581.9 323.4c0 178.5-260.5 323.3-581.9 323.3s-581.9-144.8-581.9-323.3"
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00339 0 0 -.00339 -23.002 79.602)"
    />
    <path
      d="M13127 15931.3c0-203.7 297.1-368.7 663.5-368.7 366.5 0 663.5 165 663.5 368.7 0 203.6-297 368.7-663.5 368.7-366.4 0-663.5-165.1-663.5-368.7"
      style={{
        fill: "#f28475",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00297 0 0 -.00297 -23.002 79.602)"
    />
    <path
      d="M7921.63 14998.9c-41.39 0-75.61 32.9-76.97 74.6-12.8 389.3 83.75 666.2 142.45 796.5 18.71 41.6 54.82 71.8 99.07 82.9 44.45 11.1 90.54 1.5 126.8-26.4 53.07-41 104.54-92.4 154.32-142.2 44.3-44.3 90.1-90 120.06-109.5 57.87 185.4 159.66 441.2 232.46 554.7 25.78 40.2 69.6 65.6 117.24 68.1 47.36 2.4 93.89-18.2 123.7-55.5 47.98-60 308.69-367.6 728.73-534.5 583.11-231.8 872.81-269.4 875.71-269.8 42.1-5.2 72.2-43.6 67.1-85.7-5.1-42.2-43.7-72.4-85.6-67.3-12.3 1.5-308.9 38.9-914.16 279.5-443.3 176.3-723.79 497.5-787.14 575.4-58.16-94.6-154.33-327.8-212.93-517.3-13.32-43.2-45.07-77.5-87-94.2-42.02-16.7-88.65-13.5-127.96 8.8-47.88 27.1-99.45 78.6-159.17 138.3-44.4 44.4-90.2 90.2-133.77 124.6-53.42-120.8-137.46-369.8-125.92-721.4 1.45-42.5-31.94-78.1-74.45-79.5-.88-.1-1.75-.1-2.57-.1"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00488 0 0 -.00488 -23.002 79.602)"
    />
    <path
      d="M15670.1 15976.8c-27.7-7.9-54.2 15.3-50.1 43.9 12.1 85.9 51.1 233.6 175.9 253.3 165.4 26 183.3-117.5 168.5-160.3-17.3-50-59.2-81.2-141.7-98.5-50.6-10.6-109.6-26-152.6-38.4M15217.2 15976.8c27.8-7.9 54.3 15.3 50.2 43.9-12.1 85.9-51.2 233.6-176 253.3-165.4 26-183.2-117.5-168.4-160.3 17.3-50 59.2-81.2 141.7-98.5 50.6-10.6 109.6-26 152.5-38.4"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00304 0 0 -.00304 -23.002 79.602)"
    />
    <path
      d="M15146.5 15407.8c93-71.6 188-126.4 286.8-154 98.3-26.9 198.8-28.6 291-1 23 6.9 44.9 17 67.9 24.5 20.5 12.5 42.1 23.3 62.7 35.9 18.7 15.3 40.5 27.1 58 44.3l26.3 26c9.4 8.2 18.8 16.4 25.8 26.9 65.7 76.2 112.3 173.1 145.7 283.2l1.5 4.8c14.8 49 66.8 76.8 115.7 61.9 38.7-11.8 64.1-46.6 65.8-84.8 6.3-142-31.8-294-118.6-421.9-85.4-128.3-219.9-229.2-372.6-271.4l-57.9-12.3c-19.4-3.8-38.5-8.7-58.3-9l-58.8-3.3-29.4-1.4c-9.7.6-19.4 1.9-29.1 2.9-38.7 5-78.1 6.4-114.9 18.5-37.2 10.1-74.9 18.9-108.8 36.8-140.2 60.1-253.3 163.4-328.1 282.3-25.4 40.3-13.2 93.5 27.1 118.8 31.3 19.7 70.6 16.8 98.4-4.6l3.8-3.1"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.003 0 0 -.003 -23.002 79.602)"
    />
    <path
      d="M9880.84 16078c-447.5 0-1084.94-171.8-1603.63-981.4-4.91-7.4-8.92-13.9-12.86-20.4l-7.09-11.4c-798.9-1290.8-9.6-2812.9-1.54-2828.1l53.45-100.9-110.23-29.6c-23.1-6.3-570.66-161.8-562.37-691.2 4.92-314.3 310.92-800.6 742.07-996 216.18-98.9 507.54-194 866.14-283 1382.32-343.72 2850.62-345.26 4246.02-4.4 371.9 90.7 675 187.5 901.1 287.7 430.7 195.2 736.6 681.5 741.3 995.7 8.3 529.4-539 684.9-562.3 691.3l-109.7 29.9 53.1 100.5c8.1 15.2 797.2 1537.3-2 2828.4-6.4 10.7-12.6 20.7-19 30.4-763.5 1191.8-1783.5 1003-2077.9 916.8-538.9-157.8-930.2-571.1-930.2-982.8 0-50.2-40.6-90.8-90.8-90.8-3.2 0-6.4.1-9.5.4-3.1-.3-6.3-.4-9.4-.4-50.2 0-90.9 40.6-90.9 90.8 0 411.6-391.2 824.9-930.1 982.8-94.2 27.6-262.9 65.7-473.66 65.7m1491.46-6382.74c-732.2 0-1462.75 88.1-2171.38 264.32-369.46 91.62-671.45 190.52-897.64 293.92-500.1 226.6-842.58 782.2-848.47 1158.7-7.37 469.3 342.31 731.7 594.16 834.6-69.01 151.4-198.56 471.6-274.49 882.9-139.57 756.2-25.96 1458.5 328.41 2031.1l6.52 10.5c5.03 8.3 10.12 16.5 15.43 24.5 299.14 466.9 673.86 786.8 1113.08 949.9 377.35 140.1 781.08 154.2 1167.78 41 464.7-136 825.2-434.4 979.2-783.7 154 349.3 514.6 647.7 979.4 783.8 386.5 113.2 790.4 99 1167.7-41.1 439.2-163.1 813.9-483.1 1113.7-951 7-10.6 14.5-22.8 21.7-34.8 353.9-571.7 467.5-1274 328.1-2030.2-75.9-411.3-205.5-731.5-274.5-883 251.8-102.8 601.4-365.2 594-834.5-5.7-376.4-348.1-931.9-848.8-1158.8-236.5-104.8-549.9-205.1-932.2-298.46-708.8-173.12-1436-259.68-2161.7-259.68"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00414 0 0 -.00414 -23.002 79.602)"
    />
    <path
      d="M12252.9 10641.8c-773 0-1544.4 93-2292.35 279-386.36 95.8-700.28 198.3-933.19 304.9-425.85 193-738.3 649.4-791.52 984.9 618.6-397.3 1712.58-856.1 3556.96-991.2 152.8-11.2 305.9-16.8 459.1-16.8 153.7 0 307.4 5.7 460.7 16.9 1875.4 137.6 2975 610 3587.4 1012-43-337.2-359.6-808.7-793.8-1005.5-243.6-107.9-570.2-212.2-970.8-310-748.5-182.8-1516.3-274.2-2282.5-274.2"
      style={{
        fill: "#d08d6f",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00384 0 0 -.00384 -23.002 79.602)"
    />
    <path
      d="M10802.2 14978c-369.9 244-590.4 540.1-688.5 911.2-30.8 116.4-46.8 260.3-43.9 410.8 91.4-86.2 205.1-183.4 344.7-286.9-1-23.4-1.3-47.3-.9-71.5 4.5-285.4 147.3-644.3 388.6-963.6"
      style={{
        fill: "#9e321d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00296 0 0 -.00296 -23.002 79.602)"
    />
    <path
      d="M11378.8 14301.5c-751.7 277.6-1281.3 674.9-1453.91 1328-49.83 187.6-64.27 431.8-30.23 670.5 31.97-33.5 68.07-69.5 108.64-107.7-3-149.6 12.9-292.5 43.5-408.2 97.5-368.6 316.5-662.8 684-905.1 171-226.2 391.8-432.4 648-577.5"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00298 0 0 -.00298 -23.002 79.602)"
    />
    <path
      d="M15747.1 11228.8c180.9 239.4 288 508.5 291.1 722.3.4 25.1-.2 49.7-1.6 73.7 106.2 80.5 191.4 155.5 258.6 221 4.8-122.3-7.2-240-32-334.1-73.5-278-238.8-500-516.1-682.9"
      style={{
        fill: "#9e321d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00395 0 0 -.00395 -23.002 79.602)"
    />
    <path
      d="M15161.2 10724.4c192 108.9 357.4 263.5 485.6 432.9 275.5 181.7 439.7 402.3 512.8 678.6 24.7 93.4 36.6 210.4 31.8 331.9 29.5 28.7 55.5 55.6 78.3 80.2 30.3-187 20.4-380.7-18.7-528-129.4-489.7-526.4-787.4-1089.8-995.6"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00398 0 0 -.00398 -23.002 79.602)"
    />
    <path
      d="M12040.2 10320.9c-353.8 0-955.4 81.7-1641 172.4-680.94 90.2-783.23 203.4-1336.66 355.1-18.62 5-37.24 10.1-55.86 15.7-58.3 16.6-115.62 34-171.48 52.5-25.13 11.4-50.08 24.6-74.48 39.8-11.26 4.1-22.45 8.2-33.59 12.3-196.48 111.4-365.83 269.5-496.96 443-183.89 243.2-292.69 516.7-296.1 734.2-.3 18.4-.06 36.6.67 54.4 30.85-22.8 63.41-46.1 97.73-69.8 1.7-1.5 3.59-2.6 5.53-4.1 31.1-21.4 63.53-43 97.43-64.7 52.57-331.4 361.2-782.3 781.86-972.9 230.08-105.2 540.17-206.5 921.82-301.2 738.89-183.7 1500.79-275.5 2264.39-275.5 756.9 0 1515.3 90.2 2254.6 270.8 395.8 96.6 718.4 199.6 959 306.2 429 194.4 741.7 660.2 784.2 993.3 26.9 17.6 52.8 35.1 77.8 52.5 7 4.5 13.8 9.4 20.6 14.2 34.5 24.3 67.2 48.2 98.2 71.7 1.5-24.4 2.1-49.4 1.7-74.9-3.3-217.4-112.1-490.8-295.9-734.1-131.1-173.3-300.3-331.3-496.6-442.8-49.1-18.1-99.4-35.6-150.8-52.4 138.1 62.6 266 151.6 378.3 256.1-55.9-42.8-115.4-82.5-177.3-119.5l-.5-.5c-1176.3-524.3-2734.4-731.8-3516.6-731.8"
      style={{
        fill: "#650000",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00389 0 0 -.00389 -23.002 79.602)"
    />
    <path
      d="M11529.8 16285.9c-191.9 14.1-364.9-65.3-496.9-209.9-169.4-184.3-271.5-474.8-260.7-809.1 14-432 212.7-818.8 488-1010.7 104.2-73.2 219.4-118.1 339.5-127.1 437.9-32.9 777.1 423.3 757.6 1018.9-19.1 595.7-389.6 1105-827.5 1137.9"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00354 0 0 -.00354 -23.002 79.602)"
    />
    <path
      d="M11462 16221.2c-160 0-306.9-68.7-427.1-200.2-170-185.1-261.6-470.9-251.6-784.2 13.2-405.7 198.8-791.4 472.9-982.5 102.4-72 210.8-112.7 322.4-121 160.7-12 311.5 45 436.6 164.9 188.5 180.8 294.7 486.5 283.9 817.9-18.6 577-375.3 1072-795.2 1103.5-14.1 1.1-28 1.6-41.9 1.6zm158.9-2155.7c-15.7 0-31.4.6-47.3 1.8-123.5 9.3-243.1 53.9-355.3 132.7-290.6 202.6-487.3 608.8-501.1 1034.7-10.7 330.4 87.3 633.3 268.8 831 142.7 156.2 328.6 234.3 522.8 220 452.6-33.9 836.8-557.7 856.4-1167.5 11.7-355-99.3-671.3-304.2-867.8-126.8-121.5-278.1-184.9-440.1-184.9"
      style={{
        fill: "#11116d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00354 0 0 -.00354 -23.002 79.602)"
    />
    <path
      d="M11529.8 16285.9c-191.9 14.1-364.9-65.3-496.9-209.9-17.9-36.8-33.3-75.8-46.6-116.2-29.9-90.9-39.8-220.6 27.8-282.8 58-53.4 131.8-18.2 199.8-23.2 111.8-8 217.7-150.4 219.5-295.6 1.8-145-101-264.8-212.8-248.5-77.9 11.5-165.4 76.3-227.5 23.9-45.7-38.6-51.7-123.5-45.3-197.6 21.9-253 141.1-493.5 297.2-663.8 4.9-5.5 10.1-10.9 15.2-16 104.2-73.2 219.4-118.1 339.5-127.1 437.9-32.9 777.1 423.3 757.6 1018.9-19.1 595.7-389.6 1105-827.5 1137.9"
      style={{
        fill: "#1595ff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00354 0 0 -.00354 -23.002 79.602)"
    />
    <path
      d="M11947.5 14367.5c362.5-27.3 642.6 377 625.8 903-16.9 526.2-324.5 974.8-687 1002.2-362.4 27.3-642.6-377-625.7-903.2 16.9-526 324.4-974.7 686.9-1002"
      style={{
        fill: "#640005",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00349 0 0 -.00349 -23.002 79.602)"
    />
    <path
      d="M11354.6 15872.7c-68.8 5-143.5-30.6-202.2 23.5-68.3 62.9-58.3 194.2-28 286.1 13.4 41 29 80.4 47.1 117.7-171.4-186.6-274.8-480.5-263.8-818.8 14.2-437.2 215.3-828.6 493.8-1022.9-5.1 5.2-10.4 10.7-15.4 16.2-158 172.5-278.6 415.8-300.7 671.8-6.5 75-.4 160.9 45.8 200 62.9 53 151.3-12.6 230.2-24.2 113.2-16.6 217.2 104.8 215.4 251.5-1.8 147-109 290.9-222.2 299.1"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0035 0 0 -.0035 -23.002 79.602)"
    />
    <path
      d="M11346.6 16300c-225.5 0-439.6-98.5-603.1-277.3-210.6-229.3-324.5-575-312.4-948.1 15.6-483 234.8-929.1 572-1164.2 137-96.3 285.3-151.2 440-162.8 19.8-1.5 39.8-2.2 59.5-2.2 216 0 423.6 91.4 584.3 257.5 222.7 230 343.2 582.8 330.6 968-22.6 703.1-467.2 1286-1012 1326.9-19.8 1.5-39.5 2.2-58.9 2.2zm0-210.4c14.2 0 28.7-.5 43.1-1.6 432.6-32.5 798.6-535.6 817.5-1124 18.5-568.3-293.5-1008.2-704.6-1008.2-14.5 0-29.1.5-43.8 1.6-118.7 8.9-232.5 53.3-335.4 125.6-271.9 189.6-468.2 571.7-482.1 998.4-10.7 330.3 90.3 617.2 257.5 799.3 120.5 131.8 275.5 208.9 447.8 208.9"
      style={{
        fill: "#640005",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00358 0 0 -.00358 -23.002 79.602)"
    />
    <path
      d="M15057.4 16280.7c190.8 19.3 365.4-55.2 500.8-195.6 173.7-179.1 283.3-465.7 281.5-799.2-2.2-430.7-189.7-821.4-458.9-1020.2-101.7-75.6-215.3-123.6-334.8-135.8-435.3-44.5-785.6 400.8-782.4 994.8 3 594 358.4 1111.4 793.8 1156"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00355 0 0 -.00355 -23.002 79.602)"
    />
    <path
      d="M15030 16214.8c-417.5-42.9-759.5-545.7-762.4-1121.1-1.8-330.4 112.2-632.1 305-807.2 127.9-116.1 280-168.8 439.4-152.5 110.9 11.3 217.9 54.8 317.8 129.2 268 197.9 442.5 587.2 444.7 991.8 1.7 312.4-97.3 594.7-271.7 774.4-132.1 137-300 202.9-472.8 185.4zm-75-2149.7c-155.2 0-301.6 58.9-426.8 172.5-209.5 190.3-328.5 502.4-326.7 856.5 3.2 608 371.8 1140.2 821.8 1186.3h.1c193.1 19.6 380.4-53.3 526.8-205.1 186.2-192 292.1-491.1 290.3-820.7-2.2-424.7-187.3-834.7-471.3-1044.5-109.6-81.4-227.5-129.2-350.5-141.8-21.3-2.2-42.6-3.2-63.7-3.2"
      style={{
        fill: "#11116d",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00355 0 0 -.00355 -23.002 79.602)"
    />
    <path
      d="M15057.4 16280.7c190.8 19.3 365.4-55.2 500.8-195.6 18.8-36.2 35.2-74.6 49.5-114.6 32.3-89.7 45.6-218.7-20-282.5-56.4-54.8-130.8-21.7-198.5-28.5-111.1-11-212.8-155.7-210.7-300.4 2.1-144.5 107.7-261.2 218.8-241.9 77.3 13.5 162.7 80.5 226 30 46.5-37.2 54.8-121.7 50.4-195.7-14.9-252.6-127.2-495.5-278.1-669.4-4.8-5.6-9.8-11.1-14.8-16.4-101.7-75.6-215.3-123.6-334.8-135.8-435.3-44.5-785.6 400.8-782.4 994.8 3 594 358.4 1111.4 793.8 1156"
      style={{
        fill: "#1595ff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00355 0 0 -.00355 -23.002 79.602)"
    />
    <path
      d="M15050.6 14363.1c-360.5-37-650.6 358.3-648 882.9 2.7 524.7 297 980 657.4 1017 360.5 37 650.5-358.2 647.9-882.8-2.6-524.7-296.9-980-657.3-1017.1"
      style={{
        fill: "#640005",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0035 0 0 -.0035 -23.002 79.602)"
    />
    <path
      d="M15556.9 15869.5c68.4 6.9 143.7-26.5 200.7 28.8 66.4 64.6 52.8 195 20.2 285.8-14.5 40.4-31.1 79.2-50.1 115.9 175.7-181.2 286.6-471.1 284.8-808.3-2.3-435.7-191.9-830.9-464.1-1031.9 5 5.4 10 11 14.9 16.6 152.7 175.9 266.2 421.6 281.3 677 4.5 74.9-4 160.3-51 198-64.1 51.1-150.4-16.7-228.6-30.4-112.3-19.5-219.1 98.5-221.3 244.6-2.1 146.4 100.7 292.8 213.2 303.9"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.0035 0 0 -.0035 -23.002 79.602)"
    />
    <path
      d="M14937 16300c-26.4 0-53.2-1.3-79.6-4-542.2-55.5-969.5-648.4-973-1350-2.1-378 124.1-723.1 346.4-946.5 162.8-163.7 362.5-250.3 577.4-250.3 26.6 0 53.6 1.4 80.3 4.1 154 15.8 300.3 74.5 434.8 174.6 329.1 243 535.5 693.5 538 1175.3 2 372.2-121 713.7-337.4 936.9-161.6 167.4-370.1 259.9-586.9 259.9zm0-209.8c165.7 0 316.5-72 436.3-196.2 171.6-177 280-460.2 278.2-789.7-2.2-425.6-187.5-811.7-453.4-1008.1-100.6-74.7-212.8-122.1-330.9-134.2-19.8-2-39.6-3-59-3-402.4 0-717.1 426.2-714.1 986 3 586.9 354.2 1098.2 784.4 1142.3 19.6 1.9 39.2 2.9 58.5 2.9"
      style={{
        fill: "#640005",
        fillOpacity: 1,
        fillRule: "nonzero",
        stroke: "none",
      }}
      transform="matrix(.00359 0 0 -.00359 -23.002 79.602)"
    />
  </svg>
)

export default SvgComponent
